import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'

import EventInformation from "../components/event-information"

class EventDetails extends React.Component {
    render() {
      const event = get(this.props, 'data.contentfulEvent')
  
      
      return (
        <div class="text-center">
            <EventInformation event={event}></EventInformation>
        </div>
      )
    }
  }
  
export default EventDetails

export const pageQuery = graphql`
    query EventByYearIdentifier($yearIdentifier: Int!) {
        contentfulEvent(yearIdentifier: { eq: $yearIdentifier }) {
            speakers {
                photo {
                fluid(maxWidth: 317) {
                        ...GatsbyContentfulFluid
                    }
                }
                jobTitle
                name
                bio {
                childMarkdownRemark {
                    internal {
                        content
                        }
                    }
                }
                linkedInProfileLink
            }
            performers {
                photo {
                fluid(maxWidth: 317) {
                        ...GatsbyContentfulFluid
                    }
                }
                name
                bio {
                    childMarkdownRemark {
                        internal {
                            content
                            }
                        }
                }
                title
            }
            teams {
                id
                name
                teamMembers {
                  photo {
                    file {
                      url
                    }
                  }
                  title
                  name
                  id
                }
            }
            startTime
            endTime
            description {
                childMarkdownRemark {
                    html
                }
            }
            name
            yearIdentifier
            image {
                fluid(maxWidth: 2500, quality: 100) {
                    ...GatsbyContentfulFluid
                }
            }
            location
            relatedVideos {
                name
                linkToVideo
            }
        }
    }
`
